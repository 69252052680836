import React from 'react'

// Components
import Layout from '../layouts'
import Nav from '../components/Nav'
import Footer from '../components/Footer'

function CookiesPage() {
  return (
    <Layout pageName="cookies">
      <Nav />
      <div className="maxwidth-xsmall">
        <div className="content legal">
          <h1>Cookie Policy</h1>
          <div className="last-revision">Last revision: February 20, 2019</div>
          <p>
            Bizy ("us", "we", or "our") uses cookies on
            bizy.com and affiliated websites (collectively the "Site"). Our
            Cookies Policy explains what cookies are, how we use cookies, how
            third-parties we partner with may use cookies on the Site, and your
            choices regarding cookies. Please read this Cookies Policy in
            conjunction with our Privacy Policy (available
            <a href="/privacy"> here)</a>
            , which sets out additional details on how
            we use personally identifiable information and your various rights.
          </p>
          <ol>
            <li>
              <h3>What is a cookie?</h3>
              <p>
                Cookies are small text files sent by us to your computer or
                mobile device. They are unique to your account or your browser.
                Session-based cookies last only while your browser is open and
                are automatically deleted when you close your browser.
                Persistent cookies last until you or your browser delete them or
                until they expire.
              </p>
              <p>
              To find out more about cookies, visit
                <a href="http://www.allaboutcookies.org/"> this site</a>
              </p>
            </li>
            <li>
              <h2>Cookies we may use</h2>
              <h3>Strictly Necessary Cookies</h3>
              <p>
                These cookies are necessary for the website to function and
                cannot be switched off in our systems. They are usually only set
                in response to actions made by you which amount to a request for
                services, such as setting your privacy preferences, logging in
                or filling in forms. You can set your browser to block or alert
                you about these cookies, but some parts of the site will not
                then work. These cookies do not store any personally
                identifiable information.
              </p>
              <p>
                Cookies used:
              </p>
              <ul>
                <li>
                  <p>productionToken</p>
                </li>
              </ul>
              <p />
              <h3>Performance Cookies</h3>
              <p>
                These cookies allow us to count visits and traffic sources so we
                can measure and improve the performance of our site. They help
                us to know which pages are the most and least popular and see
                how visitors move around the site. All information these cookies
                collect is aggregated and therefore anonymous. If you do not
                allow these cookies we will not know when you have visited our
                site, and will not be able to monitor its performance.
              </p>
              <p>
              Cookies used:
              </p>
              <ul>
                <li>
                  <p>_ga</p>
                </li>
                <li>
                  <p>_gid</p>
                </li>
                <li>
                  <p>ajs_anonymous_id</p>
                </li>
                <li>
                  <p>ajs_group_id</p>
                </li>
                <li>
                  <p>ajs_user_id</p>
                </li>
                <li>
                  <p>Mixpanel device id cookie</p>
                </li>
              </ul>
              <p />
              <h3>Functional Cookies</h3>
              <p>
                These cookies enable the website to provide enhanced
                functionality and personalisation. They may be set by us or by
                third party providers whose services we have added to our pages.
                If you do not allow these cookies then some or all of these
                services may not function properly.
              </p>
              <p>
              Cookies used:
              </p>
              <ul>
                <li>
                  <p>consent</p>
                </li>
              </ul>
              <p />
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default CookiesPage
